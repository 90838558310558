import React from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Link,
  Flex,
  Container,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import blogConfig from "../constants/blogConfig";
import SoulmateCompatibility from "../images/soulmate-compatibility.jpg";
import MarriageCompatibility from "../images/marriage-compatibility.jpg";
import ZodiacCompatibility from "../images/zodiac-compatibility.jpg";
import Moonphases from "../images/moonphases.jpg";
import Numerology from "../images/numerology.jpg";
import { Link as RouterLink } from "react-router-dom";
import parse, { domToReact } from "html-react-parser";

export const blogToolsImageMap = {
  "soulmate-compatibility": SoulmateCompatibility,
  "marriage-compatibility": MarriageCompatibility,
  "zodiac-compatibility": ZodiacCompatibility,
  "moon-phase-compatibility": Moonphases,
  "numerology-compatibility": Numerology,
};

function BlogPost() {
  const { id } = useParams();
  const blog = blogConfig.find((post) => post.id === id);

  if (!blog) {
    return <Text>Blog post not found</Text>;
  }

  const options = {
    replace: ({ name, attribs, children }) => {
      if (name === "a" && attribs.href) {
        return (
          <RouterLink to={attribs.href}>{domToReact(children)}</RouterLink>
        );
      }
    },
  };

  return (
    <>
      <Helmet>
        <title>{blog.seoTitle} | Compatibility Match Calculator</title>
        <meta name="description" content={blog.seoDescription} />
        <meta name="keywords" content={blog.seoKeywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href={`https://compatibilitymatchcalculator.com/blogs/${blog.id}`}
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={blog.seoTitle} />
        <meta property="og:description" content={blog.seoDescription} />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://compatibilitymatchcalculator.com/blogs/${blog.id}`}
        />
        <meta property="og:image" content={blog.imageUrl} />
        <meta
          property="og:image:alt"
          content={`${blog.seoTitle} on Compatibility Match Calculator`}
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.seoTitle} />
        <meta name="twitter:description" content={blog.seoDescription} />
        <meta name="twitter:image" content={blog.imageUrl} />
        <meta
          name="twitter:image:alt"
          content={`${blog.seoTitle} on Compatibility Match Calculator`}
        />
      

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Flex
        minH="100vh" // Ensures full viewport height for vertical centering
        justifyContent="center" // Centers horizontally
        py={8}
        px={4}
      >
        <Container
          maxW={["100%", "90%", "80%", "900px"]} // Responsive max-width
          p={8}
          borderRadius="md"
        >
          <Helmet>
            <title>{blog.seoTitle}</title>
            <meta name="description" content={blog.seoDescription} />
          </Helmet>

          <Heading as="h1" size="xl" mb={16}>
            {blog.title}
          </Heading>

          <Image
            src={blogToolsImageMap[blog.id]}
            alt={blog.title}
            mb={16}
            borderRadius={16}
          />

          <Box mx="auto">
            <div>{parse(blog.content, options)}</div>
          </Box>
        </Container>
      </Flex>
    </>
  );
}

export default BlogPost;
