import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

function PrivacyPolicyPage() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Compatibility Match Calculator</title>
        <meta
          name="description"
          content="Read our privacy policy to understand how Compatibility Match Calculator collects, uses, and protects your data. We prioritize your privacy and transparency."
        />
        <meta
          name="keywords"
          content="privacy policy, data protection, user privacy, data usage, Compatibility Match Calculator"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://compatibilitymatchcalculator.com/privacy-policy"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="Privacy Policy - Compatibility Match Calculator"
        />
        <meta
          property="og:description"
          content="Understand how Compatibility Match Calculator collects, uses, and protects your data by reading our comprehensive privacy policy."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://compatibilitymatchcalculator.com/privacy-policy"
        />
        <meta
          property="og:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Privacy Policy of Compatibility Match Calculator"
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Privacy Policy - Compatibility Match Calculator"
        />
        <meta
          name="twitter:description"
          content="Learn how Compatibility Match Calculator handles your data and protects your privacy through our detailed privacy policy."
        />
        <meta
          name="twitter:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Privacy Policy of Compatibility Match Calculator"
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Box maxW={["100%", "90%", "80%", "900px"]} mx="auto" py={16} px={8}>
        <Heading as="h1" size="xl" mb={8} textAlign="center">
          Privacy Policy
        </Heading>
        <VStack spacing={6} align="stretch">
          <Text fontSize="lg">
            At Compatibility Match Calculator, your privacy is important to us.
            This privacy policy outlines the types of personal information we
            receive and collect when you use our website, as well as some of the
            steps we take to safeguard your information. We hope this will help
            you make an informed decision about sharing personal information
            with us.
          </Text>

          <Heading as="h2" size="md" mt={8}>
            Information We Collect
          </Heading>
          <Text fontSize="lg">
            The only information we collect from users is through website
            analytics tools. This includes data such as your IP address, browser
            type, the pages you visit on our site, and the time you spend on
            each page. This data is used solely for the purpose of understanding
            how our website is used and improving the user experience.
          </Text>

          <Heading as="h2" size="md" mt={8}>
            Use of Information
          </Heading>
          <Text fontSize="lg">
            The data collected through analytics tools is used to improve our
            website and ensure that our content meets the needs and preferences
            of our users. We do not collect personal information like names,
            email addresses, or payment details. Additionally, we do not share
            any of the data collected with third parties.
          </Text>

          <Heading as="h2" size="md" mt={8}>
            Future Changes
          </Heading>
          <Text fontSize="lg">
            In the future, we may choose to display advertisements on our
            website. If and when ads are added, we will update this privacy
            policy to reflect any additional data collection practices or
            sharing policies related to the advertisements.
          </Text>

          <Heading as="h2" size="md" mt={8}>
            Your Consent
          </Heading>
          <Text fontSize="lg">
            By using our website, you consent to the collection and use of
            information as outlined in this privacy policy. If we make changes
            to our privacy practices, we will update this page so that you are
            always aware of what information we collect, how we use it, and
            under what circumstances we may disclose it.
          </Text>

          <Heading as="h2" size="md" mt={8}>
            Contact Us
          </Heading>
          <Text fontSize="lg">
            If you have any questions about this privacy policy or our privacy
            practices, please feel free to contact us through our contact page.
          </Text>
        </VStack>
      </Box>
    </>
  );
}

export default PrivacyPolicyPage;
