// constants/toolsConfig.js

const toolsConfig = [
  {
    id: "soulmate-compatibility",
    title: "Soulmate Compatibility Calculator",
    description:
      "Discover the compatibility between you and your soulmate using astrology and numerology.",
    seoTitle: "Soulmate Compatibility Tool - Find Your Perfect Match",
    seoDescription:
      "Use our Soulmate Compatibility Tool to discover how well you and your partner align. Explore compatibility based on birthdate, zodiac signs, and personality traits.",
    seoKeywords:
      "soulmate compatibility tool, find your soulmate, love calculator, relationship compatibility, birthdate compatibility",
    imageUrl:
      "https://compatibilitymatchcalculator.com/soulmate-compatibility.jpg",
  },
  {
    id: "marriage-compatibility",
    title: "Marriage Compatibility Calculator",
    description:
      "Assess your marriage compatibility based on shared values, life goals, and personalities.",
    seoTitle: "Marriage Compatibility Tool - Assess Your Marital Harmony",
    seoDescription:
      "Our Marriage Compatibility Tool helps you evaluate your compatibility with your partner. Analyze your relationship based on zodiac signs, numerology, and core values.",
    seoKeywords:
      "marriage compatibility tool, marital harmony, relationship compatibility, zodiac marriage compatibility, numerology marriage tool",
    imageUrl:
      "https://compatibilitymatchcalculator.com/marriage-compatibility.jpg",
  },
  {
    id: "zodiac-compatibility",
    title: "Zodiac Compatibility Calculator",
    description:
      "Explore your zodiac compatibility and see how your signs align for a harmonious marriage.",
    seoTitle: "Zodiac Compatibility Tool - Discover Your Astrological Match",
    seoDescription:
      "Use our Zodiac Compatibility Tool to find out how your zodiac signs influence your relationship. Understand the astrological dynamics between you and your partner.",
    seoKeywords:
      "zodiac compatibility tool, astrological match, zodiac signs, relationship astrology, zodiac compatibility calculator",
    imageUrl:
      "https://compatibilitymatchcalculator.com/zodiac-compatibility.jpg",
  },
  {
    id: "moon-phase-compatibility",
    title: "Moon Phase Compatibility Calculator",
    description:
      "Understand your moon phase compatibility and the emotional dynamics of your relationship.",
    seoTitle:
      "Moon Phase Compatibility Tool - Align Your Relationship with Lunar Cycles",
    seoDescription:
      "Discover how the moon phase at your birth influences your relationship with our Moon Phase Compatibility Tool. Analyze emotional and instinctual compatibility.",
    seoKeywords:
      "moon phase compatibility tool, lunar cycles, birth moon phase, emotional compatibility, moon influence on relationships",
    imageUrl: "https://compatibilitymatchcalculator.com/moonphases.jpg",
  },
  {
    id: "numerology-compatibility",
    title: "Numerology Compatibility Calculator",
    description:
      "Assess your relationship based on key numerology numbers, including Life Path and Destiny numbers.",
    seoTitle:
      "Numerology Compatibility Tool - Analyze Your Relationship by the Numbers",
    seoDescription:
      "Explore the impact of numerology on your relationship with our Numerology Compatibility Tool. Calculate compatibility based on Life Path, Destiny, and Soul Urge numbers.",
    seoKeywords:
      "numerology compatibility tool, relationship numerology, Life Path number, Destiny number, Soul Urge number, numerology calculator",
    imageUrl: "https://compatibilitymatchcalculator.com/numerology.jpg",
  },
];

export default toolsConfig;
