import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Heading,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import {
  calculateZodiacCompatibility,
  calculateLifePathNumber,
  calculateNumerologyCompatibility,
  calculatePersonalityCompatibility,
  calculateValuesCompatibility,
} from "../../utils/compatibilityCalculators";
import ZodiacSignSelect from "../ZodiacSignSelect";
import PersonalitySelect from "../PersonalitySelect";
import ValuesSelect from "../ValuesSelect";
import { Link } from "react-router-dom";

const MarriageCompatibilityTool = () => {
  const [birthdate1, setBirthdate1] = useState("");
  const [birthdate2, setBirthdate2] = useState("");
  const [zodiacSign1, setZodiacSign1] = useState("");
  const [zodiacSign2, setZodiacSign2] = useState("");
  const [personalities1, setPersonalities1] = useState([]);
  const [personalities2, setPersonalities2] = useState([]);
  const [values1, setValues1] = useState([]);
  const [values2, setValues2] = useState([]);
  const [result, setResult] = useState(null);

  const calculateCompatibility = () => {
    const lifePath1 = calculateLifePathNumber(birthdate1);
    const lifePath2 = calculateLifePathNumber(birthdate2);

    const zodiacScore = calculateZodiacCompatibility(zodiacSign1, zodiacSign2);
    const numerologyScore = calculateNumerologyCompatibility(
      lifePath1,
      lifePath2
    );
    const personalityScore = calculatePersonalityCompatibility(
      personalities1,
      personalities2
    );
    const valuesScore = calculateValuesCompatibility(values1, values2);

    const weightedScore =
      zodiacScore * 0.3 +
      numerologyScore * 0.3 +
      personalityScore * 0.2 +
      valuesScore * 0.2;

    const finalScore = weightedScore * 10;

    setResult(Number.isNaN(finalScore) ? 0 : finalScore.toFixed(2));
  };

  return (
    <Box mx="auto">
      <SimpleGrid columns={[1, null, 2]} spacing={10}>
        {/* Partner 1 Section */}
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 1
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate1}
            onChange={(e) => setBirthdate1(e.target.value)}
            mb={4}
          />

          <Text mb={2}>Select Zodiac Sign:</Text>
          <ZodiacSignSelect
            value={zodiacSign1}
            onChange={(e) => setZodiacSign1(e.target.value)}
          />

          <Text mt={4} mb={2}>
            Select Personality Trait:
          </Text>
          <PersonalitySelect
            value={personalities1}
            onChange={(e) => setPersonalities1(e.map(({ value }) => value))}
          />

          <Text mb={2} mt={4}>
            Select Core Value:
          </Text>
          <ValuesSelect
            value={values1}
            onChange={(e) => setValues1(e.map(({ value }) => value))}
          />
        </Box>

        {/* Partner 2 Section */}
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 2
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate2}
            onChange={(e) => setBirthdate2(e.target.value)}
            mb={4}
          />

          <Text mb={2}>Select Zodiac Sign:</Text>
          <ZodiacSignSelect
            value={zodiacSign2}
            onChange={(e) => setZodiacSign2(e.target.value)}
            mb={4}
          />

          <Text mb={2}>Select Personality Trait:</Text>
          <PersonalitySelect
            value={personalities2}
            onChange={(e) => setPersonalities2(e.map(({ value }) => value))}
          />

          <Text mt={4} mb={2}>
            Select Core Value:
          </Text>
          <ValuesSelect
            value={values2}
            onChange={(e) => setValues2(e.map(({ value }) => value))}
            mb={4}
          />
        </Box>
      </SimpleGrid>

      <Flex justifyContent={"center"} mt={12} mb={12}>
        <Button
          colorScheme="primary"
          alignSelf="center"
          onClick={calculateCompatibility}
          mt={4}
        >
          Calculate Compatibility
        </Button>
      </Flex>

      {result != undefined && (
        <>
          <Text fontWeight="bolder" mt={6} fontSize="xl" color="pink.500">
            Your Marriage Compatibility Score: {result} / 100 💍 💍 💍 💍 💍
          </Text>

          <Text mt={4} fontSize="sm" color="gray.500">
            Disclaimer: This compatibility score is for entertainment purposes
            only and should not be taken as professional advice. Compatibility
            in relationships involves many factors beyond what this tool can
            measure.
          </Text>
        </>
      )}

      <Text mt={8}>
        <Link to="/blogs/marriage-compatibility" className="links">
          <strong>Learn more about how the score is calculated </strong>
        </Link>
      </Text>
    </Box>
  );
};

export default MarriageCompatibilityTool;
