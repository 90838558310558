// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};
const colors = {
  primary: {
    50: "#FFE6E9",
    100: "#FFB3C1",
    200: "#FF8A9F",
    300: "#FF6180",
    400: "#FF385F",
    500: "#FF6B81", // Warm pink
    600: "#D9596D",
    700: "#B3475A",
    800: "#8C3648",
    900: "#662536",
  },
  secondary: {
    50: "#F0E9F7",
    100: "#D9C7EC",
    200: "#C1A3E1",
    300: "#A980D6",
    400: "#915CBB",
    500: "#7D5BA6", // Soft purple
    600: "#6A4C90",
    700: "#563E79",
    800: "#443163",
    900: "#2E2147",
  },
  accent: {
    50: "#FFF5E6",
    100: "#FFE6C0",
    200: "#FFDBA1",
    300: "#FFC285",
    400: "#FFAB6B",
    500: "#FFC5A1", // Peach
    600: "#CC957B",
    700: "#996F59",
    800: "#664A39",
    900: "#332519",
  },
  background: {
    light: "#FFF0F3", // Light pinkish white
    dark: "#1E202A", // Dark gray
  },
  text: {
    light: "#333333", // Dark gray text for light mode
    dark: "#FFFFFF", // White text for dark mode
  },
};
const theme = extendTheme({
  config,
  colors,
  styles: {
    global: (props) => ({
      body: {
        bg:
          props.colorMode === "light"
            ? colors.background.light
            : colors.background.dark,
        color:
          props.colorMode === "light" ? colors.text.light : colors.text.dark,
      },
    }),
  },
});

export default theme;
