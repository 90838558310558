import React from "react";
import {
  Box,
  Heading,
  SimpleGrid,
  Image,
  Text,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import toolsConfig from "../constants/toolsConfig";
import { blogToolsImageMap } from "./BlogPost";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function ToolsList() {
  const { colorMode } = useColorMode();

  return (
    <>
      <Helmet>
        <title>Compatibility Tools - Compatibility Match Calculator</title>
        <meta
          name="description"
          content="Explore our wide range of compatibility tools, including soulmate compatibility, marriage compatibility, zodiac compatibility, numerology compatibility, and more. Find your perfect match."
        />
        <meta
          name="keywords"
          content="compatibility tools, soulmate compatibility, marriage compatibility, zodiac compatibility, numerology tools, relationship calculators"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://compatibilitymatchcalculator.com/tools"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="Compatibility Tools - Compatibility Match Calculator"
        />
        <meta
          property="og:description"
          content="Discover various compatibility tools designed to help you find your perfect match, from soulmate and marriage compatibility to zodiac and numerology tools."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://compatibilitymatchcalculator.com/tools"
        />
        <meta
          property="og:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Compatibility Tools on Compatibility Match Calculator"
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Compatibility Tools - Compatibility Match Calculator"
        />
        <meta
          name="twitter:description"
          content="Explore our comprehensive suite of compatibility tools, including soulmate, marriage, zodiac, and numerology compatibility calculators."
        />
        <meta
          name="twitter:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Compatibility Tools on Compatibility Match Calculator"
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Box p={8} maxW={["100%", "90%", "80%", "900px"]} margin="auto">
        <Heading as="h1" size="xl" mb={8}>
          Tools
        </Heading>
        <SimpleGrid columns={[1, 1, 2, 3]} spacing={10}>
          {toolsConfig.map((tool) => (
            <Link
              as={RouterLink}
              to={`/tools/${tool.id}`}
              key={tool.id}
              _hover={{ textDecoration: "none" }}
            >
              <Box
                p={6}
                bg={colorMode === "light" ? "primary.100" : "secondary.100"}
                rounded="md"
                shadow="md"
                transition="all 0.3s ease"
                _hover={{ transform: "translateY(-5px)", shadow: "lg" }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minHeight="500px" // Set a consistent minimum height
              >
                <Box>
                  <Image
                    src={blogToolsImageMap[tool.id]}
                    alt={tool.title}
                    mb={4}
                    rounded="md"
                    objectFit="cover"
                  />
                  <Heading as="h3" size="md" mb={2} color="primary.800">
                    {tool.title}
                  </Heading>
                  <Text color="gray.600" mt={2} flexGrow={1}>
                    {tool.description}
                  </Text>
                </Box>
                <Link
                  href={`/tools/${tool.id}`}
                  color="secondary.500"
                  mt={4}
                  fontWeight="bold"
                  _hover={{ color: "secondary.600" }}
                >
                  Get Your Score Now
                </Link>
              </Box>
            </Link>
          ))}
        </SimpleGrid>
      </Box>
    </>
  );
}

export default ToolsList;
