import {
  Box,
  Heading,
  Image,
  Link as ChakraLink,
  Text,
  Flex,
  useColorMode,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { blogToolsImageMap } from "../pages/BlogPost";
import { Link } from "react-router-dom";

// Carousel Component for Tools and Blogs
export default function Carousel({ items, type = "tools", ...props }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { colorMode } = useColorMode();

  return (
    <Box p={8} {...props}>
      <Slider {...settings}>
        {items.map((item) => (
          <ChakraLink
            as={Link}
            to={type === "blogs" ? `/blogs/${item.id}` : `/tools/${item.id}`}
            key={item.id}
            _hover={{ textDecoration: "none" }}
          >
            <Flex
              flexDirection={"column"}
              key={item.id}
              p={6}
              bg={colorMode === "light" ? "primary.100" : "secondary.100"}
              rounded="md"
              minHeight={type === "blogs" ? "550px" : "420px"}
            >
              <Image
                src={blogToolsImageMap[item.id]}
                alt={item.title}
                mb={4}
                rounded="md"
                objectFit="cover"
              />
              <Heading as="h3" size="md" mb={4} color="primary.800">
                {item.title}
              </Heading>
              <Text color="gray.600" flexGrow={1}>
                {item.description || item.seoDescription}
              </Text>

              <Text
                color="secondary.500"
                mt={2} // Add spacing to separate from the description
                fontWeight="bold"
                _hover={{ color: "secondary.600" }}
              >
                {type === "blogs" ? "Read More" : "Get Your Score Now"}
              </Text>
            </Flex>
          </ChakraLink>
        ))}
      </Slider>
    </Box>
  );
}
