import {
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import blogConfig from "../constants/blogConfig";
import toolsConfig from "../constants/toolsConfig";

// Footer Component
export default function Footer() {
  return (
    <Box as="footer" p={8} bg="gray.800" color="white" margin={"auto"}>
      <Box maxW={["100%", "90%", "80%", "900px"]} mx="auto">
        <SimpleGrid columns={[1, 2, 3]} spacing={24}>
          <VStack align="flex-start">
            <ChakraLink as={Link} to="/tools">
              <Heading size="md" mb={4}>
                Tools
              </Heading>
            </ChakraLink>
            {toolsConfig.map((tool) => (
              <ChakraLink as={Link} to={`/tools/${tool.id}`} key={tool.id}>
                {tool.title}
              </ChakraLink>
            ))}
          </VStack>

          <VStack align="flex-start">
            <ChakraLink as={Link} to="/blogs">
              <Heading size="md" mb={4}>
                Blogs
              </Heading>
            </ChakraLink>
            {blogConfig.map((blog) => (
              <ChakraLink as={Link} to={`/blogs/${blog.id}`} key={blog.id}>
                {blog.miniTitle}
              </ChakraLink>
            ))}
          </VStack>

          <VStack align="flex-start">
            <ChakraLink as={Link} to="/about">
              About
            </ChakraLink>
            <ChakraLink as={Link} to="/contact">
              Contact
            </ChakraLink>
            <ChakraLink as={Link} to="/privacy-policy">
              Privacy Policy
            </ChakraLink>
          </VStack>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
