import React from "react";
import {
  Flex,
  Heading,
  Image,
  Link as ChakraLink,
  IconButton,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  VStack,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FaSun, FaMoon } from "react-icons/fa";
import { Link } from "react-router-dom";
import blogConfig from "../constants/blogConfig";
import toolsConfig from "../constants/toolsConfig";
import logo from "../images/logo.png";

function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMenuClick = () => {
    onClose(); // Close the mobile menu after an item is clicked
  };

  return (
    <Flex
      as="header"
      w="100%"
      px={8}
      py={4}
      align="center"
      justify="space-between"
      bg={colorMode === "light" ? "primary.200" : "secondary.900"}
    >
      {/* Logo and Title */}
      <ChakraLink href="/" _hover={{ textDecoration: "none" }}>
        <Flex align="center">
          <Image
            src={logo}
            alt="Compatibility Match Calculator - Find Your Perfect Match"
            boxSize="40px"
          />
          <Heading as="h1" size="md" ml={3}>
            Compatibility Match Calculator
          </Heading>
        </Flex>
      </ChakraLink>

      {/* Desktop Menu */}
      <Flex align="center" display={["none", "none", "flex"]}>
        {/* Tools Submenu */}
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            mr={4}
            bg="secondary.500"
            _hover={{ bg: "secondary.600" }}
          >
            Tools
          </MenuButton>
          <MenuList>
            {toolsConfig.map((tool) => (
              <MenuItem
                key={tool.id}
                as={Link}
                to={`/tools/${tool.id}`}
                _hover={{ textDecoration: "none" }}
              >
                {tool.title}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        {/* Blogs Submenu */}
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            mr={4}
            bg="secondary.500"
            _hover={{ bg: "secondary.600" }}
          >
            Blogs
          </MenuButton>
          <MenuList>
            {blogConfig.map((blog) => (
              <MenuItem
                key={blog.id}
                as={Link}
                to={`/blogs/${blog.id}`}
                _hover={{ textDecoration: "none" }}
              >
                {blog.miniTitle}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        {/* Contact and About Links */}
        <ChakraLink as={Link} to="/contact" mr={8}>
          Contact
        </ChakraLink>
        <ChakraLink as={Link} to="/about">
          About
        </ChakraLink>

        {/* Theme Switcher */}
        <IconButton
          ml={8}
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
          isRound="true"
          aria-label="Toggle theme"
          bg="transparent"
          color="white"
          _hover={{ bg: "secondary.500" }}
        />
      </Flex>

      {/* Mobile Menu Button */}
      <IconButton
        aria-label="Open Menu"
        size="lg"
        mr={2}
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        display={["flex", "flex", "none"]}
        onClick={isOpen ? onClose : onOpen}
      />

      {/* Mobile Menu */}
      {isOpen && (
        <Box
          display={["flex", "flex", "none"]}
          pos="absolute"
          top="64px"
          left="0"
          right="0"
          bg={colorMode === "light" ? "primary.100" : "secondary.900"}
          zIndex="overlay"
          p={4}
        >
          <VStack spacing={4} align="stretch">
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="secondary.500"
              >
                Tools
              </MenuButton>
              <MenuList>
                {toolsConfig.map((tool) => (
                  <MenuItem
                    key={tool.id}
                    as={Link}
                    to={`/tools/${tool.id}`}
                    onClick={handleMenuClick}
                  >
                    {tool.title}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="secondary.500"
              >
                Blogs
              </MenuButton>
              <MenuList>
                {blogConfig.map((blog) => (
                  <MenuItem
                    key={blog.id}
                    as={Link}
                    to={`/blogs/${blog.id}`}
                    onClick={handleMenuClick}
                  >
                    {blog.miniTitle}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <ChakraLink as={Link} to="/contact" onClick={handleMenuClick}>
              Contact
            </ChakraLink>
            <ChakraLink as={Link} to="/about" onClick={handleMenuClick}>
              About
            </ChakraLink>

            <IconButton
              onClick={() => {
                toggleColorMode();
                handleMenuClick();
              }}
              icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
              isRound="true"
              aria-label="Toggle theme"
              bg="transparent"
              color="white"
              _hover={{ bg: "secondary.500" }}
            />
          </VStack>
        </Box>
      )}
    </Flex>
  );
}

export default Header;
