import React from "react";
import { Select } from "@chakra-ui/react";

const zodiacSigns = [
  "Aries",
  "Taurus",
  "Gemini",
  "Cancer",
  "Leo",
  "Virgo",
  "Libra",
  "Scorpio",
  "Sagittarius",
  "Capricorn",
  "Aquarius",
  "Pisces",
];

const ZodiacSignSelect = ({
  value,
  onChange,
  placeholder = "Select Zodiac Sign",
  ...props
}) => {
  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...props}
    >
      {zodiacSigns.map((sign) => (
        <option key={sign} value={sign}>
          {sign}
        </option>
      ))}
    </Select>
  );
};

export default ZodiacSignSelect;
