import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Link as ChakraLink,
  VStack,
} from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import blogConfig from "../constants/blogConfig";
import HeroImage from "../images/hero-image.jpg";
import toolsConfig from "../constants/toolsConfig";
import { Helmet } from "react-helmet-async";

// Header Component

// Hero Section Component
function HeroSection() {
  return (
    <Flex
      as="section"
      direction={["column", "column", "row"]}
      align="center"
      justify="space-between"
      p={8}
      gap={16}
      margin={"auto"}
    >
      <VStack align="flex-start" spacing={6}>
        <Heading as="h2" size="2xl">
          Calculate Your Compatibility: Soulmate, Marriage, and Zodiac Tools
        </Heading>
        <Text fontSize="lg">
          Use our free compatibility calculators to find your soulmate, check
          marriage compatibility, and explore zodiac matching. Start your
          journey to love and relationship harmony today.
        </Text>

        <Button
          as={ChakraLink}
          href="/tools"
          colorScheme="secondary"
          variant="solid"
          size="lg"
          _hover={{
            bg: "secondary.600",
            textDecoration: "none",
            color: "white",
          }} // Ensure text remains visible
        >
          Explore Compatibility Tools
        </Button>
        <Button
          as={ChakraLink}
          href="/blogs"
          colorScheme="secondary"
          variant="outline"
          size="lg"
          _hover={{
            bg: "secondary.500",
            textDecoration: "none",
            color: "white",
          }} // Ensure text is visible on hover
        >
          Read Our Blog
        </Button>
      </VStack>
      <Image
        src={HeroImage}
        style={{ borderRadius: "16px" }}
        alt="Calculate Your Compatibility: Soulmate, Marriage, and Zodiac Tools"
        boxSize="300px"
      />
    </Flex>
  );
}

// Home Page Component
function HomePage() {
  return (
    <>
      <Helmet>
        <title>Compatibility Match Calculator - Find Your Perfect Match</title>
        <meta
          name="description"
          content="Discover your relationship compatibility with our advanced tools. Explore soulmate, marriage, zodiac, and numerology compatibility effortlessly."
        />
        <meta
          name="keywords"
          content="compatibility calculator, relationship tools, soulmate compatibility, marriage compatibility, zodiac compatibility, numerology compatibility"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://compatibilitymatchcalculator.com/"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="Compatibility Match Calculator - Find Your Perfect Match"
        />
        <meta
          property="og:description"
          content="Discover your relationship compatibility with our advanced tools. Explore soulmate, marriage, zodiac, and numerology compatibility effortlessly."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://compatibilitymatchcalculator.com/"
        />
        <meta
          property="og:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Compatibility Match Calculator Homepage"
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Compatibility Match Calculator - Find Your Perfect Match"
        />
        <meta
          name="twitter:description"
          content="Discover your relationship compatibility with our advanced tools. Explore soulmate, marriage, zodiac, and numerology compatibility effortlessly."
        />
        <meta
          name="twitter:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Compatibility Match Calculator Homepage"
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Box maxW={["100%", "90%", "80%", "900px"]} margin="auto">
        <HeroSection />
        <Heading as="h2" size="lg" textAlign="center" mt={8}>
          Our Tools
        </Heading>
        <Carousel items={toolsConfig} />

        <Heading as="h2" size="lg" textAlign="center" mt={8}>
          Latest Blogs
        </Heading>
        <Carousel items={blogConfig} type="blogs" mb={8} />
      </Box>
    </>
  );
}

export default HomePage;
