import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Heading,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import {
  calculateLifePathNumber,
  calculateDestinyNumber,
  calculateSoulUrgeNumber,
  calculateNumerologyCompatibilityScore,
} from "../../utils/compatibilityCalculators";
import { Link } from "react-router-dom";

const NumerologyCompatibilityTool = () => {
  const [birthdate1, setBirthdate1] = useState("");
  const [birthdate2, setBirthdate2] = useState("");
  const [fullName1, setFullName1] = useState("");
  const [fullName2, setFullName2] = useState("");
  const [result, setResult] = useState(null);

  const calculateCompatibility = () => {
    const lifePath1 = calculateLifePathNumber(birthdate1);
    const lifePath2 = calculateLifePathNumber(birthdate2);
    const destiny1 = calculateDestinyNumber(fullName1);
    const destiny2 = calculateDestinyNumber(fullName2);
    const soulUrge1 = calculateSoulUrgeNumber(fullName1);
    const soulUrge2 = calculateSoulUrgeNumber(fullName2);

    const numerologyScore = calculateNumerologyCompatibilityScore(
      lifePath1,
      lifePath2,
      destiny1,
      destiny2,
      soulUrge1,
      soulUrge2
    );

    setResult(Number.isNaN(numerologyScore) ? 0 : numerologyScore.toFixed(2));
  };
  return (
    <Box mx="auto">
      <SimpleGrid columns={[1, null, 2]} spacing={10}>
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 1
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate1}
            onChange={(e) => setBirthdate1(e.target.value)}
            mb={4}
          />
          <Text mb={2}>Enter Full Name:</Text>
          <Input
            type="text"
            value={fullName1}
            placeholder="Enter Full Name"
            onChange={(e) => setFullName1(e.target.value)}
            mb={4}
          />
        </Box>

        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 2
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate2}
            onChange={(e) => setBirthdate2(e.target.value)}
            mb={4}
          />
          <Text mb={2}>Enter Full Name:</Text>
          <Input
            type="text"
            value={fullName2}
            placeholder="Enter Full Name"
            onChange={(e) => setFullName2(e.target.value)}
            mb={4}
          />
        </Box>
      </SimpleGrid>

      <Flex justifyContent={"center"} mt={12} mb={12}>
        <Button
          colorScheme="primary"
          alignSelf="center"
          onClick={calculateCompatibility}
          mt={4}
        >
          Calculate Compatibility
        </Button>
      </Flex>

      {result != undefined && (
        <>
          <Text fontWeight="bolder" mt={6} fontSize="xl" color="pink.500">
            Your Numerology Compatibility Score: {result} / 100 🔢 🔢 🔢 🔢 🔢
          </Text>

          <Text mt={4} fontSize="sm" color="gray.500">
            Disclaimer: This compatibility score is for entertainment purposes
            only and should not be taken as professional advice. Compatibility
            in relationships involves many factors beyond what this tool can
            measure.
          </Text>
        </>
      )}

      <Text mt={8}>
        <Link to="/blogs/numerology-compatibility" className="links">
          <strong>Learn more about how the score is calculated </strong>
        </Link>
      </Text>
    </Box>
  );
};

export default NumerologyCompatibilityTool;
