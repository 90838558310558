import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Compatibility Match Calculator</title>
        <meta
          name="description"
          content="Learn more about Compatibility Match Calculator and our mission to help you discover your relationship compatibility through various tools, including soulmate, marriage, zodiac, and numerology compatibility calculators."
        />
        <meta
          name="keywords"
          content="about Compatibility Match Calculator, relationship tools, compatibility tools, soulmate calculator, marriage compatibility, zodiac compatibility, numerology compatibility"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://compatibilitymatchcalculator.com/about"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="About Us - Compatibility Match Calculator"
        />
        <meta
          property="og:description"
          content="Discover the mission behind Compatibility Match Calculator and how we strive to help you explore your relationship compatibility with our advanced tools."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://compatibilitymatchcalculator.com/about"
        />
        <meta
          property="og:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="About Compatibility Match Calculator"
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="About Us - Compatibility Match Calculator"
        />
        <meta
          name="twitter:description"
          content="Learn more about Compatibility Match Calculator and our mission to help you discover your relationship compatibility through various tools."
        />
        <meta
          name="twitter:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="About Compatibility Match Calculator"
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Box maxW={["100%", "90%", "80%", "900px"]} mx="auto" py={16} px={8}>
        <Heading as="h1" size="xl" mb={8} textAlign="center">
          About Compatibility Match Calculator
        </Heading>
        <VStack spacing={6} align="stretch">
          <Text fontSize="lg">
            Welcome to Compatibility Match Calculator, your go-to destination
            for exploring the deeper connections in your relationships. Our
            platform offers a variety of tools designed to help you discover
            compatibility in various aspects of life, from numerology to moon
            phases and more.
          </Text>

          <Text fontSize="lg">
            Our mission is to provide you with insightful, accurate, and
            easy-to-use tools that can help you better understand your
            relationships. Whether you're curious about your soulmate
            compatibility, want to explore the dynamics of your marriage, or are
            interested in how the moon phases influence your emotions, we've got
            you covered.
          </Text>

          <Text fontSize="lg">
            Our tools use reliable algorithms based on established principles
            and are designed to be user-friendly and accessible to everyone. We
            are constantly working to improve our platform, adding new features,
            and expanding our knowledge base to serve you better.
          </Text>

          <Text fontSize="lg">
            Thank you for choosing Compatibility Match Calculator. We are
            excited to help you explore the hidden dynamics of your
            relationships.
          </Text>

          <Text fontSize="lg" fontWeight="bold" mt={8}>
            Disclaimer:
          </Text>
          <Text fontSize="lg">
            The Compatibility Match Calculator is intended for entertainment
            purposes only. While our tools are based on established principles,
            they should not be taken as definitive measures of your
            relationship’s potential or future. Relationships are complex, and
            no tool can fully predict or determine the outcome of a
            relationship. We encourage users to enjoy the experience but to make
            relationship decisions based on thoughtful consideration,
            communication, and shared values.
          </Text>
        </VStack>
      </Box>
    </>
  );
};

export default AboutPage;
