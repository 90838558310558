export const calculateZodiacCompatibility = (sign1, sign2) => {
  const compatibilityMatrix = {
    Aries: {
      Aries: 7,
      Taurus: 5,
      Gemini: 8,
      Cancer: 6,
      Leo: 9,
      Virgo: 4,
      Libra: 7,
      Scorpio: 5,
      Sagittarius: 9,
      Capricorn: 4,
      Aquarius: 8,
      Pisces: 6,
    },
    Taurus: {
      Aries: 5,
      Taurus: 9,
      Gemini: 6,
      Cancer: 8,
      Leo: 5,
      Virgo: 9,
      Libra: 6,
      Scorpio: 8,
      Sagittarius: 4,
      Capricorn: 9,
      Aquarius: 5,
      Pisces: 8,
    },
    Gemini: {
      Aries: 8,
      Taurus: 6,
      Gemini: 7,
      Cancer: 5,
      Leo: 8,
      Virgo: 6,
      Libra: 9,
      Scorpio: 4,
      Sagittarius: 8,
      Capricorn: 5,
      Aquarius: 9,
      Pisces: 6,
    },
    Cancer: {
      Aries: 6,
      Taurus: 8,
      Gemini: 5,
      Cancer: 7,
      Leo: 6,
      Virgo: 8,
      Libra: 5,
      Scorpio: 9,
      Sagittarius: 4,
      Capricorn: 8,
      Aquarius: 5,
      Pisces: 9,
    },
    Leo: {
      Aries: 9,
      Taurus: 5,
      Gemini: 8,
      Cancer: 6,
      Leo: 7,
      Virgo: 5,
      Libra: 9,
      Scorpio: 5,
      Sagittarius: 9,
      Capricorn: 4,
      Aquarius: 8,
      Pisces: 6,
    },
    Virgo: {
      Aries: 4,
      Taurus: 9,
      Gemini: 6,
      Cancer: 8,
      Leo: 5,
      Virgo: 7,
      Libra: 5,
      Scorpio: 8,
      Sagittarius: 5,
      Capricorn: 9,
      Aquarius: 4,
      Pisces: 8,
    },
    Libra: {
      Aries: 7,
      Taurus: 6,
      Gemini: 9,
      Cancer: 5,
      Leo: 9,
      Virgo: 5,
      Libra: 7,
      Scorpio: 5,
      Sagittarius: 8,
      Capricorn: 6,
      Aquarius: 9,
      Pisces: 5,
    },
    Scorpio: {
      Aries: 5,
      Taurus: 8,
      Gemini: 4,
      Cancer: 9,
      Leo: 5,
      Virgo: 8,
      Libra: 5,
      Scorpio: 7,
      Sagittarius: 5,
      Capricorn: 8,
      Aquarius: 5,
      Pisces: 9,
    },
    Sagittarius: {
      Aries: 9,
      Taurus: 4,
      Gemini: 8,
      Cancer: 4,
      Leo: 9,
      Virgo: 5,
      Libra: 8,
      Scorpio: 5,
      Sagittarius: 7,
      Capricorn: 5,
      Aquarius: 9,
      Pisces: 6,
    },
    Capricorn: {
      Aries: 4,
      Taurus: 9,
      Gemini: 5,
      Cancer: 8,
      Leo: 4,
      Virgo: 9,
      Libra: 6,
      Scorpio: 8,
      Sagittarius: 5,
      Capricorn: 7,
      Aquarius: 6,
      Pisces: 8,
    },
    Aquarius: {
      Aries: 8,
      Taurus: 5,
      Gemini: 9,
      Cancer: 5,
      Leo: 8,
      Virgo: 4,
      Libra: 9,
      Scorpio: 5,
      Sagittarius: 9,
      Capricorn: 6,
      Aquarius: 7,
      Pisces: 5,
    },
    Pisces: {
      Aries: 6,
      Taurus: 8,
      Gemini: 6,
      Cancer: 9,
      Leo: 6,
      Virgo: 8,
      Libra: 5,
      Scorpio: 9,
      Sagittarius: 6,
      Capricorn: 8,
      Aquarius: 5,
      Pisces: 7,
    },
  };

  return compatibilityMatrix[sign1]?.[sign2] || 0; // Default to 0 if no match is found
};

export const calculateLifePathNumber = (birthdate) => {
  const digits = birthdate.replace(/-/g, "").split("").map(Number);
  let sum = digits.reduce((a, b) => a + b, 0);

  while (sum > 9 && ![11, 22, 33].includes(sum)) {
    sum = sum
      .toString()
      .split("")
      .map(Number)
      .reduce((a, b) => a + b, 0);
  }

  return sum;
};

export const calculateNumerologyCompatibility = (lifePath1, lifePath2) => {
  const compatibilityMatrix = {
    1: { 1: 8, 2: 6, 3: 7, 4: 5, 5: 8, 6: 9, 7: 6, 8: 7, 9: 5 },
    2: { 1: 6, 2: 9, 3: 5, 4: 8, 5: 6, 6: 8, 7: 7, 8: 6, 9: 8 },
    3: { 1: 7, 2: 5, 3: 8, 4: 6, 5: 9, 6: 7, 7: 5, 8: 8, 9: 6 },
    4: { 1: 5, 2: 8, 3: 6, 4: 9, 5: 5, 6: 7, 7: 8, 8: 7, 9: 6 },
    5: { 1: 8, 2: 6, 3: 9, 4: 5, 5: 8, 6: 6, 7: 7, 8: 8, 9: 7 },
    6: { 1: 9, 2: 8, 3: 7, 4: 7, 5: 6, 6: 9, 7: 6, 8: 5, 9: 8 },
    7: { 1: 6, 2: 7, 3: 5, 4: 8, 5: 7, 6: 6, 7: 9, 8: 5, 9: 8 },
    8: { 1: 7, 2: 6, 3: 8, 4: 7, 5: 8, 6: 5, 7: 5, 8: 9, 9: 7 },
    9: { 1: 5, 2: 8, 3: 6, 4: 6, 5: 7, 6: 8, 7: 8, 8: 7, 9: 9 },
  };

  return compatibilityMatrix[lifePath1]?.[lifePath2] || 0;
};

export const calculatePersonalityCompatibility = (traits1, traits2) => {
  let score = 0;

  traits1.forEach((trait1) => {
    if (traits2.includes(trait1)) {
      score += 2; // Full points for an exact match
    }
  });

  const maxScore = Math.max(traits1.length, traits2.length) * 2;
  let result = (score / maxScore) * 10; // Normalize to a 0-10 scale
  return Number.isNaN(result) ? 0 : result;
};

export const calculateValuesCompatibility = (values1, values2) => {
  let score = 0;

  values1.forEach((value1) => {
    if (values2.includes(value1)) {
      score += 2; // Full points for an exact match
    }
  });

  const maxScore = Math.max(values1.length, values2.length) * 2;
  let result = (score / maxScore) * 10; // Normalize to a 0-10 scale
  return Number.isNaN(result) ? 0 : result;
};

// Calculate the moon phase for a given date
const getMoonPhase = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0-based index
  const day = date.getDate();

  // Convert the year, month, and day into a format suitable for the algorithm
  const c = Math.floor((year / 100) * 4);
  const e = 4 - Math.floor((year / 100) * 4) - c;
  const g = Math.floor(8 * (year % 19) + 13) % 19;
  const h =
    (Math.floor((day + 9 + Math.floor((30 * g + 4) / 17)) * 8) % 30) + 1;
  const j = Math.floor(year / 400) + Math.floor(g / 8) - Math.floor(year / 4);
  const l = Math.floor(year * 0.6 - j - 1);
  const n = (year - l) % 28;

  const m = (h - (Math.floor(0.05 * day) + e * 10) + ((n + 5) % 10)) % 8;
  return Math.abs(m);
};

// Calculate the moon phase compatibility based on phase difference
const getMoonPhaseCompatibilityScore = (phase1, phase2) => {
  const phaseDifference = Math.abs(phase1 - phase2);

  if (phaseDifference === 0) {
    return 10; // Perfect match
  } else if (phaseDifference <= 2) {
    return 8; // Very compatible
  } else if (phaseDifference <= 4) {
    return 6; // Moderately compatible
  } else if (phaseDifference <= 6) {
    return 4; // Less compatible
  } else {
    return 2; // Least compatible
  }
};

// Calculate emotional needs compatibility
const getEmotionalNeedsCompatibilityScore = (phase1, phase2) => {
  // Custom logic to evaluate emotional needs alignment
  if (phase1 === phase2) return 9; // Identical needs
  if (Math.abs(phase1 - phase2) <= 2) return 7; // Similar needs
  return 5; // Different needs
};

// Calculate instinctive reactions compatibility
const getInstinctiveReactionsScore = (phase1, phase2) => {
  // Custom logic to evaluate instinctive reactions alignment
  if (phase1 === phase2) return 9; // Identical reactions
  if (Math.abs(phase1 - phase2) <= 2) return 7; // Similar reactions
  return 5; // Different reactions
};

// Final calculation for moon phase compatibility
export const calculateMoonPhaseCompatibility = (birthdate1, birthdate2) => {
  if (!birthdate1 || !birthdate2) {
    return 0; // Return 0 for compatibility if either birthdate is missing
  }
  
  const phase1 = getMoonPhase(new Date(birthdate1));
  const phase2 = getMoonPhase(new Date(birthdate2));

  const birthMoonScore = getMoonPhaseCompatibilityScore(phase1, phase2) * 0.5;
  const emotionalNeedsScore =
    getEmotionalNeedsCompatibilityScore(phase1, phase2) * 0.3;
  const instinctiveReactionsScore =
    getInstinctiveReactionsScore(phase1, phase2) * 0.2;

  const finalScore =
    birthMoonScore + emotionalNeedsScore + instinctiveReactionsScore;
  return finalScore.toFixed(2); // Return a score out of 100
};

// Utility to get the Zodiac sign from a birthdate
const getZodiacSign = (birthdate) => {
  const date = new Date(birthdate);
  const month = date.getUTCMonth() + 1; // getUTCMonth() returns month from 0-11
  const day = date.getUTCDate();

  if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return "Aquarius";
  } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return "Pisces";
  } else if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
    return "Aries";
  } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
    return "Taurus";
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
    return "Gemini";
  } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
    return "Cancer";
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return "Leo";
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return "Virgo";
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return "Libra";
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return "Scorpio";
  } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
    return "Sagittarius";
  } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
    return "Capricorn";
  }
};

// Compatibility scores between signs (simplified)
const zodiacCompatibilityMatrix = {
  Aries: {
    Aries: 8,
    Taurus: 6,
    Gemini: 7,
    Cancer: 5,
    Leo: 9,
    Virgo: 6,
    Libra: 7,
    Scorpio: 5,
    Sagittarius: 9,
    Capricorn: 6,
    Aquarius: 7,
    Pisces: 5,
  },
  Taurus: {
    Aries: 6,
    Taurus: 9,
    Gemini: 5,
    Cancer: 8,
    Leo: 6,
    Virgo: 9,
    Libra: 6,
    Scorpio: 7,
    Sagittarius: 5,
    Capricorn: 9,
    Aquarius: 5,
    Pisces: 8,
  },
  Gemini: {
    Aries: 7,
    Taurus: 5,
    Gemini: 9,
    Cancer: 5,
    Leo: 8,
    Virgo: 6,
    Libra: 9,
    Scorpio: 5,
    Sagittarius: 8,
    Capricorn: 6,
    Aquarius: 9,
    Pisces: 5,
  },
  Cancer: {
    Aries: 5,
    Taurus: 8,
    Gemini: 5,
    Cancer: 9,
    Leo: 6,
    Virgo: 8,
    Libra: 6,
    Scorpio: 9,
    Sagittarius: 5,
    Capricorn: 7,
    Aquarius: 5,
    Pisces: 9,
  },
  Leo: {
    Aries: 9,
    Taurus: 6,
    Gemini: 8,
    Cancer: 6,
    Leo: 9,
    Virgo: 6,
    Libra: 8,
    Scorpio: 6,
    Sagittarius: 9,
    Capricorn: 6,
    Aquarius: 8,
    Pisces: 5,
  },
  Virgo: {
    Aries: 6,
    Taurus: 9,
    Gemini: 6,
    Cancer: 8,
    Leo: 6,
    Virgo: 9,
    Libra: 6,
    Scorpio: 8,
    Sagittarius: 6,
    Capricorn: 9,
    Aquarius: 6,
    Pisces: 8,
  },
  Libra: {
    Aries: 7,
    Taurus: 6,
    Gemini: 9,
    Cancer: 6,
    Leo: 8,
    Virgo: 6,
    Libra: 9,
    Scorpio: 6,
    Sagittarius: 8,
    Capricorn: 6,
    Aquarius: 9,
    Pisces: 6,
  },
  Scorpio: {
    Aries: 5,
    Taurus: 7,
    Gemini: 5,
    Cancer: 9,
    Leo: 6,
    Virgo: 8,
    Libra: 6,
    Scorpio: 9,
    Sagittarius: 6,
    Capricorn: 9,
    Aquarius: 5,
    Pisces: 8,
  },
  Sagittarius: {
    Aries: 9,
    Taurus: 5,
    Gemini: 8,
    Cancer: 5,
    Leo: 9,
    Virgo: 6,
    Libra: 8,
    Scorpio: 6,
    Sagittarius: 9,
    Capricorn: 6,
    Aquarius: 9,
    Pisces: 6,
  },
  Capricorn: {
    Aries: 6,
    Taurus: 9,
    Gemini: 6,
    Cancer: 7,
    Leo: 6,
    Virgo: 9,
    Libra: 6,
    Scorpio: 9,
    Sagittarius: 6,
    Capricorn: 9,
    Aquarius: 6,
    Pisces: 8,
  },
  Aquarius: {
    Aries: 7,
    Taurus: 5,
    Gemini: 9,
    Cancer: 5,
    Leo: 8,
    Virgo: 6,
    Libra: 9,
    Scorpio: 5,
    Sagittarius: 9,
    Capricorn: 6,
    Aquarius: 9,
    Pisces: 6,
  },
  Pisces: {
    Aries: 5,
    Taurus: 8,
    Gemini: 5,
    Cancer: 9,
    Leo: 5,
    Virgo: 8,
    Libra: 6,
    Scorpio: 8,
    Sagittarius: 6,
    Capricorn: 8,
    Aquarius: 6,
    Pisces: 9,
  },
};

// Calculate compatibility based on zodiac signs
export const calculateZodiacCompatibilityFromBirthdate = (
  birthdate1,
  birthdate2
) => {
  // Check for empty or invalid inputs
  if (!birthdate1 || !birthdate2) {
    return 0; // Return 0 for compatibility if either birthdate is missing
  }

  const zodiacSign1 = getZodiacSign(birthdate1);
  const zodiacSign2 = getZodiacSign(birthdate2);

  // Ensure that valid zodiac signs were returned
  if (!zodiacSign1 || !zodiacSign2) {
    return 0; // Return 0 if we cannot determine the zodiac signs
  }

  const compatibilityScore =
    zodiacCompatibilityMatrix[zodiacSign1][zodiacSign2] * 10;
  return compatibilityScore || 0; // Return 0 if the score is undefined
};

// Helper function to convert letters to corresponding numbers
const letterToNumber = (letter) => {
  const charCode = letter.toUpperCase().charCodeAt(0);
  return charCode >= 65 && charCode <= 90 ? charCode - 64 : 0;
};

// Calculate Destiny Number from full name
export const calculateDestinyNumber = (fullName) => {
  const sum = fullName
    .split("")
    .reduce((acc, letter) => acc + letterToNumber(letter), 0);
  return sum % 9 || 9;
};

// Calculate Soul Urge Number from vowels in full name
export const calculateSoulUrgeNumber = (fullName) => {
  const vowels = fullName.match(/[AEIOUaeiou]/g) || [];
  const sum = vowels.reduce((acc, vowel) => acc + letterToNumber(vowel), 0);
  return sum % 9 || 9;
};

// Calculate Numerology Compatibility
export const calculateNumerologyCompatibilityScore = (
  lifePath1,
  lifePath2,
  destiny1,
  destiny2,
  soulUrge1,
  soulUrge2
) => {
  // Compatibility scores are based on comparing the Life Path, Destiny, and Soul Urge Numbers
  const compatibilityMatrix = {
    1: { 1: 8, 2: 6, 3: 7, 4: 5, 5: 8, 6: 9, 7: 6, 8: 7, 9: 5 },
    2: { 1: 6, 2: 9, 3: 5, 4: 8, 5: 6, 6: 8, 7: 7, 8: 6, 9: 8 },
    // ... Complete the matrix for all numbers ...
  };

  const lifePathScore = compatibilityMatrix[lifePath1]?.[lifePath2] || 0;
  const destinyScore = compatibilityMatrix[destiny1]?.[destiny2] || 0;
  const soulUrgeScore = compatibilityMatrix[soulUrge1]?.[soulUrge2] || 0;

  // Apply weights to calculate the final score
  const finalScore =
    lifePathScore * 0.4 + destinyScore * 0.3 + soulUrgeScore * 0.3;
  return finalScore * 10; // Scale to a score out of 100
};
