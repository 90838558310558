// pages/tools/[id].js

import React from "react";
import { useParams } from "react-router-dom"; // Or use 'next/router' for Next.js
import toolsConfig from "../constants/toolsConfig";
import { Helmet } from "react-helmet-async";
import { Container, Flex, Heading, Image } from "@chakra-ui/react";
import { blogToolsImageMap } from "./BlogPost";
import SoulmateCompatibilityTool from "../components/tools/SoulmateCompatibility";
import MarriageCompatibilityTool from "../components/tools/MarriageCompatibilityTool";
import ZodiacCompatibilityTool from "../components/tools/ZodiacCompatibilityTool";
import NumerologyCompatibilityTool from "../components/tools/NumerologyCompatibilityTool";

import MoonPhaseCompatibilityTool from "../components/tools/MoonPhaseCompatibilityTool";

const toolIdCompMap = {
  "soulmate-compatibility": SoulmateCompatibilityTool,
  "marriage-compatibility": MarriageCompatibilityTool,
  "zodiac-compatibility": ZodiacCompatibilityTool,
  "moon-phase-compatibility": MoonPhaseCompatibilityTool,
  "numerology-compatibility": NumerologyCompatibilityTool,
};

function ToolPage() {
  const { id } = useParams(); // For React Router

  const tool = toolsConfig.find((tool) => tool.id === id);
  const ToolComponent = toolIdCompMap[id];

  if (!tool || !ToolComponent) {
    return <p>Tool not found.</p>;
  }

  return (
    <>
      <Helmet>
        <title>{tool.seoTitle} | Compatibility Match Calculator</title>
        <meta name="description" content={tool.seoDescription} />
        <meta name="keywords" content={tool.seoKeywords} />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href={`https://compatibilitymatchcalculator.com/tools/${tool.id}`}
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content={tool.seoTitle} />
        <meta property="og:description" content={tool.seoDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://compatibilitymatchcalculator.com/tools/${tool.id}`}
        />
        <meta property="og:image" content={tool.imageUrl} />
        <meta
          property="og:image:alt"
          content={`${tool.seoTitle} on Compatibility Match Calculator`}
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={tool.seoTitle} />
        <meta name="twitter:description" content={tool.seoDescription} />
        <meta name="twitter:image" content={tool.imageUrl} />
        <meta
          name="twitter:image:alt"
          content={`${tool.seoTitle} on Compatibility Match Calculator`}
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Flex
        minH="100vh" // Ensures full viewport height for vertical centering
        justifyContent="center" // Centers horizontally
        py={8}
        px={4}
      >
        <Container
          maxW={["100%", "90%", "80%", "900px"]} // Responsive max-width
          p={8}
          borderRadius="md"
        >
          <Heading as="h1" size="xl" mb={16}>
            {tool.title}
          </Heading>

          <Image
            src={blogToolsImageMap[tool.id]}
            alt={tool.title}
            mb={16}
            borderRadius={16}
          />

          <ToolComponent />
        </Container>
      </Flex>
    </>
  );
}

export default ToolPage;
