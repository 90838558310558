import { useTheme, useColorMode } from "@chakra-ui/react";
import React from "react";
import Select from "react-select";

const valuesOptions = [
  "Family-Oriented",
  "Career-Focused",
  "Adventure-Seeking",
  "Health and Wellness",
  "Financial Stability",
  "Spirituality",
  "Creativity",
  "Education",
  "Work-Life Balance",
  "Environmental Consciousness",
  "Community Service",
  "Personal Growth",
  "Freedom",
  "Security",
  "Integrity",
  "Honesty",
  "Loyalty",
  "Independence",
  "Collaboration",
  "Innovation",
  "Tradition",
  "Ambition",
  "Simplicity",
  "Minimalism",
  "Empathy",
  "Compassion",
  "Generosity",
  "Respect",
  "Responsibility",
  "Curiosity",
  "Adventure",
  "Love",
  "Humor",
  "Trust",
  "Equality",
  "Justice",
  "Diversity",
  "Gratitude",
  "Resilience",
  "Open-Mindedness",
  "Patience",
  "Discipline",
  "Self-Improvement",
  "Perseverance",
  "Optimism",
  "Peace",
  "Harmony",
  "Kindness",
  "Happiness",
  "Self-Care",
  "Authenticity",
  "Accountability",
];

const ValuesSelect = ({
  value,
  onChange,
  placeholder = "Select Core Values",
  ...props
}) => {
  const theme = useTheme(); // Get the Chakra UI theme
  const { colorMode } = useColorMode();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? theme.colors.pink[500]
        : state.isFocused
          ? colorMode === "light"
            ? theme.colors.pink[200]
            : theme.colors.pink[500]
          : colorMode === "light"
            ? "transparent"
            : theme.colors.gray[700],
      color: state.isSelected
        ? "white"
        : colorMode === "light"
          ? theme.colors.gray[900]
          : theme.colors.gray[100],
      "&:hover": {
        backgroundColor:
          colorMode === "light"
            ? theme.colors.pink[200]
            : theme.colors.pink[600],
        color: theme.colors.gray[900],
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor:
        colorMode === "light" ? theme.colors.pink[100] : theme.colors.pink[700],
      color:
        colorMode === "light" ? theme.colors.pink[700] : theme.colors.pink[200],
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color:
        colorMode === "light" ? theme.colors.pink[700] : theme.colors.pink[200],
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color:
        colorMode === "light" ? theme.colors.pink[500] : theme.colors.pink[300],
      ":hover": {
        backgroundColor: theme.colors.pink[500],
        color: "white",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colorMode === "light" ? "black" : "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor:
        colorMode === "light" ? theme.colors.pink[50] : theme.colors.gray[800],
    }),
  };

  return (
    <Select
      isMulti
      options={valuesOptions.map((op) => ({ value: op, label: op }))}
      selectOption={value}
      onChange={onChange}
      placeholder="Select Value Traits"
      closeMenuOnSelect={false}
      styles={customStyles}
    />
  );
};

export default ValuesSelect;
