import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  FormControl,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleMailTo = () => {
    const subject = `Contact Form Submission from ${name}`;
    const body = `Name: ${name}%0D%0AEmail: ${email}%0D%0AMessage: ${message}`;
    window.location.href = `mailto:support@yourdomain.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Compatibility Match Calculator</title>
        <meta
          name="description"
          content="Get in touch with the Compatibility Match Calculator team. Contact us with any questions, feedback, or inquiries. We’re here to help you explore your relationship compatibility."
        />
        <meta
          name="keywords"
          content="contact Compatibility Match Calculator, support, inquiries, questions, feedback"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Compatibility Match Calculator Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="canonical"
          href="https://compatibilitymatchcalculator.com/contact"
        />

        {/* Open Graph / Facebook */}
        <meta
          property="og:title"
          content="Contact Us - Compatibility Match Calculator"
        />
        <meta
          property="og:description"
          content="Have questions or feedback? Reach out to the Compatibility Match Calculator team through our contact page."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://compatibilitymatchcalculator.com/contact"
        />
        <meta
          property="og:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Contact Compatibility Match Calculator"
        />
        <meta
          property="og:site_name"
          content="Compatibility Match Calculator"
        />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact Us - Compatibility Match Calculator"
        />
        <meta
          name="twitter:description"
          content="Get in touch with the Compatibility Match Calculator team for any questions or feedback."
        />
        <meta
          name="twitter:image"
          content="https://compatibilitymatchcalculator.com/hero-image.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Contact Compatibility Match Calculator"
        />

        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
      </Helmet>

      <Box maxW={["100%", "90%", "80%", "900px"]} mx="auto" py={16} px={8}>
        <Heading as="h1" size="xl" mb={8} textAlign="center">
          Contact Us
        </Heading>
        <Text mb={8} textAlign="center">
          If you have any questions, feedback, or inquiries, feel free to reach
          out to us using the form below. We’ll get back to you as soon as
          possible.
        </Text>
        <VStack spacing={6} align="stretch">
          <FormControl id="name" isRequired>
            <FormLabel>Your Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>Your Email</FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="message" isRequired>
            <FormLabel>Your Message</FormLabel>
            <Textarea
              placeholder="Enter your message"
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormControl>
          <Button colorScheme="pink" size="lg" mt={4} onClick={handleMailTo}>
            Send Message
          </Button>
        </VStack>
      </Box>
    </>
  );
};

export default ContactPage;
