import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Heading,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { calculateMoonPhaseCompatibility } from "../../utils/compatibilityCalculators";

const MoonPhaseCompatibilityTool = () => {
  const [birthdate1, setBirthdate1] = useState("");
  const [birthdate2, setBirthdate2] = useState("");
  const [result, setResult] = useState(null);

  const calculateCompatibility = () => {
    const moonPhaseScore = calculateMoonPhaseCompatibility(
      birthdate1,
      birthdate2
    );
    const finalScore = moonPhaseScore * 10;

    setResult(Number.isNaN(finalScore) ? 0 : finalScore.toFixed(2));
  };

  return (
    <Box mx="auto">
      <SimpleGrid columns={[1, null, 2]} spacing={10}>
        {/* Partner 1 Section */}
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 1
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate1}
            onChange={(e) => setBirthdate1(e.target.value)}
            mb={4}
          />
        </Box>

        {/* Partner 2 Section */}
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Partner 2
          </Heading>
          <Text mb={2}>Enter Birthdate:</Text>
          <Input
            type="date"
            value={birthdate2}
            onChange={(e) => setBirthdate2(e.target.value)}
            mb={4}
          />
        </Box>
      </SimpleGrid>

      <Flex justifyContent={"center"} mt={12} mb={12}>
        <Button
          colorScheme="primary"
          alignSelf="center"
          onClick={calculateCompatibility}
          mt={4}
        >
          Calculate Compatibility
        </Button>
      </Flex>

      {result != undefined && (
        <>
          <Text fontWeight="bolder" mt={6} fontSize="xl" color="pink.500">
            Your Moon Phase Compatibility Score: {result} / 100 🌕 🌖 🌗 🌘 🌑
          </Text>

          <Text mt={4} fontSize="sm" color="gray.500">
            Disclaimer: This compatibility score is for entertainment purposes
            only and should not be taken as professional advice. Compatibility
            in relationships involves many factors beyond what this tool can
            measure.
          </Text>
        </>
      )}

      <Text mt={8}>
        <Link to="/blogs/moon-phase-compatibility" className="links">
          <strong>Learn more about how the score is calculated </strong>
        </Link>
      </Text>
    </Box>
  );
};

export default MoonPhaseCompatibilityTool;
